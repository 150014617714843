import axios from "axios";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";
import routes from "../../configs/api";

const getSectionWithLang = (key,lang, mode) => {
const token = localStorage.getItem(AUTH_TOKEN_KEY);
  return new Promise((resolve, reject) => {
    const URL = `${routes.GET_SECTION}?key=${key}&lang=${lang}&mode=${mode}`;
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getSectionWithLang;