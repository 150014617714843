import React, { useContext, useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import {
  COUNTRY_CURRENCY,
  SIM_PURCHASE,
  USER_DETAILS,
} from "../../constants/localstorageKeys";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/images/logo.svg";
import LanguageSelect from "../LanguageSelect";
import NavMenu from "../NabMenu";
import { toast } from "react-toastify";

const Navbars = ({ handleDrawerToggle, value }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathnames = window.location.pathname;
  const [isColor, setIsColor] = useState("");
  const { currentLocalization, setUser, user, setTopUpLink, setToastId } = useContext(AuthUserContext);
  const hasPopUp = localStorage.getItem(SIM_PURCHASE);
  const getIsColorFromPathname = (pathname) => {
    if (pathname === "/") {
      return "home";
    } else if (pathname === "/country-filter") {
      return "offers";
    } else if (pathname === "/top-up") {
      return "topUp";
    } else if (pathname === "/data-usage") {
      return "dashboard";
    } else if (pathname === "/contact-us") {
      return "contact_us";
    } else if (pathname === "/about-us") {
      return "about_us";
    } else {
      return "dashboard";
    }
  };
  const handleRoute = [
    "/users-listing",
    "/package-listing",
    "/topup-listing",
    "/my-package",
    "/data-usage",
    "/transactions-listing",
  ];

  const memoizedIsColor = useMemo(
    () => getIsColorFromPathname(pathname),
    [pathname]
  );
  const handleTopUp = () => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      navigate("/top-up")
    } else {
      const id = toast.error(t("toastsMessage.part3"), {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true
      });
      setToastId(id);
      navigate("/log-in");
      window.scroll(0, 0);
      setTopUpLink("/top-up")
    }
  }
  useEffect(() => {
    if (handleRoute.includes(pathname)) { }
    setIsColor(memoizedIsColor);
  }, [pathname, memoizedIsColor]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
      console.log("pathnames", pathnames, pathname);

      if (userDetails.role === "user" && pathnames === "/content-management") {
        navigate("/data-usage");
      }
    }
  }, []);

  return (
    <Navbar collapseOnSelect expand="xl" className={`bg-bodys ${value == "sidebar" && "pt-4 pb-4"}`}>
      <Container
        className={
          currentLocalization == "ar"
            ? "d-flex flex-row-reverse"
            : "d-flex flex-row"
        }
      >
        {value == "sidebar" && (
          <div className="d-flex">
            <Typography
              style={{ color: "white", display: "flex", zIndex: "11" }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon style={{ color: "#000" }} />
              </IconButton>
            </Typography>
          </div>
        )}
        {value == "sidebar" ? (
          <></>
        ) : (
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="logo" width={160} height={80} />
            </Link>
          </Navbar.Brand>
        )}

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={currentLocalization == "ar" ? "" : "me-auto"}></Nav>
          <Nav
            className={
              currentLocalization == "ar"
                ? "d-flex flex-row-md-reverse align-items-lg-center align-items-end"
                : "text-md-start d-flex align-items-lg-center justify-content-center"
            }
          >
            <NavLink to="/" style={{ textDecoration: "none" }}>
              <Nav.Link
                href="/"
                className={
                  isColor === "home"
                    ? "nav-text-active me-3 d-flex justify-content-center"
                    : "nav-text me-3 d-flex justify-content-center"
                }
                id="home"
                onClick={() => {
                  setIsColor("home");
                  window.scroll(0, 0);
                }}
              >
                {t("navbar.home")}
              </Nav.Link>
            </NavLink>
            {user?.role !== "member" && (
              <>
                <Nav.Link
                  href=""
                  className={
                    isColor === "offers"
                      ? "nav-text-active me-3 d-flex justify-content-center"
                      : "nav-text me-3 d-flex justify-content-center"
                  }
                  id="offers"
                  onClick={() => {
                    navigate(`/country-filter?country=SA`);
                    localStorage.setItem(COUNTRY_CURRENCY, "SAR");
                    setIsColor("offers");
                    window.scroll(0, 0);
                  }}
                >
                  {t("navbar.offers")}
                </Nav.Link>
                <div
                  className={
                    isColor === "topUp"
                      ? "nav-text-active me-3 d-flex justify-content-center"
                      : "nav-text me-3 d-flex justify-content-center"
                  }
                  id="topUp"
                  style={{ cursor: "pointer" }}
                  onClick={handleTopUp}
                >
                  {t("navbar.top_up")}
                </div>
              </>
            )}

            <NavLink to="/contact-us" style={{ textDecoration: "none", cursor: "pointer" }}>
              <Nav.Link
                href="/contact-us"
                className={
                  isColor === "contact_us"
                    ? "nav-text-active me-3 d-flex justify-content-center"
                    : "nav-text me-3 d-flex justify-content-center"
                }
                id="contact_us"
                onClick={() => {
                  setIsColor("contact_us");
                  window.scroll(0, 0);
                }}
              >
                {t("contactUs.Contact")}
              </Nav.Link>
            </NavLink>
            <NavLink to="/about-us" style={{ textDecoration: "none" }}>
              <Nav.Link
                href="/about-us"
                className={
                  isColor === "about_us"
                    ? "nav-text-active me-3 d-flex justify-content-center"
                    : "nav-text me-3 d-flex justify-content-center"
                }
                id="about_us"
                onClick={() => {
                  setIsColor("about_us");
                  window.scroll(0, 0);
                }}
              >
                {t("contactUs.aboutUs")}
              </Nav.Link>
            </NavLink>
            {user && user?.otpVerified ? (
              <NavLink
                to={
                  user && user?.role == "user"
                    ? "/data-usage"
                    : user && user?.role == "admin" ? "/users-listing" : "/content-management"
                }
                style={{ textDecoration: "none" }}
                onClick={() => window.scroll(0, 0)}
              >
                <Nav.Link
                  href="/data-usage"
                  className={
                    handleRoute.includes(pathname)
                      ? "nav-text-active me-3 d-flex justify-content-center"
                      : "nav-text me-3 d-flex justify-content-center"
                  }
                  id="dashboard"
                  onClick={() => setIsColor("dashboard")}
                >
                  {t("navbar.dashboard")}
                </Nav.Link>
              </NavLink>
            ) : (
              <Nav.Link className="d-flex justify-content-center">
                <Link
                  to="/log-in"
                  style={{ textDecoration: "none" }}
                  onClick={() => window.scroll(0, 0)}
                >
                  <button className="btn-login">{t("navbar.login")}</button>
                </Link>
              </Nav.Link>
            )}

            {user && user?.otpVerified && (
              <Nav.Link className="d-flex justify-content-center">
                <NavMenu user={user} />
              </Nav.Link>
            )}

            <Nav.Link
              className={`d-flex justify-content-center ${value == "sidebar" ? "me-3" : "me-0"
                }`}
            >
              <LanguageSelect />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbars;
